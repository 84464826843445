import * as bookings from './bookings';
import * as calendar from './calendar';
import * as chats from './chats';
import * as main from './main';
import * as pets from './pets';
import * as products from './products';
import * as subscriptions from './subscriptions';
import { generateReactiveFields } from './utils';

export const vars = {
  ...main.vars,
  ...calendar.vars,
  ...pets.vars,
  ...bookings.vars,
  ...products.vars,
  ...subscriptions.vars,
  ...chats.vars
};

export const reactiveFields = generateReactiveFields(vars);
