const { version } = require('../../package.json');
const FIREBASE = require('./config-fb-prod');

module.exports = {
  ENV: 'production',
  FIREBASE,
  BASE_API_URL: 'https://api.collarapp.uk',
  API_URL: 'https://api.collarapp.uk/graphql',
  SUB_API_URL: 'wss://api.collarapp.uk/graphql',
  MEET_URL: 'https://meet.collar.pet',
  CLIENT_ID: `WEB-${version}`,
  DYNAMIC_LINK_DOMAIN_PREFIX: 'https://collar.page.link',
  WEBSITE_PREFIX: 'https://collarapp.uk',
  BUGSNAG_KEY: 'bb7617aa0f3e7ecc53719ee7caf07d9d',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCZC4rfyCcwVu0Wjp5Hx49_HhAmegEut90'
};
