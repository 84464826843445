import { Views } from 'react-big-calendar';
import { CALENDAR_MODAL_TITLES, CALENDAR_VIEWS } from '../components/Calendar/types';
import { Booking } from '../views/Bookings/types';
import { generateVars } from './utils';
import endOfWeek from 'date-fns/endOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import { calcRange } from '../components/Calendar/utils';

type SelectedDate = {
  date: Date;
  hour: string | number;
  minute?: string | number;
  branchSlotsIds: string[];
} | null;

const initialStates: {
  selectedDate: SelectedDate;
  modalTitle: CALENDAR_MODAL_TITLES;
  newBookingDefaultAppointments: Booking[];
  calendarMessage: boolean;
  calendarTagCategoryId: string | null;
  calendarDefaultGutterSizes: number[];
  calendarSelectedBookingType: CALENDAR_VIEWS;
  calendarRange: { from: Date; to: Date };
  calendarView: 'day' | 'week' | 'month';
  calendarDatesCheckInCheckOutCount: { checkIn: number; checkOut: number }[];
  calendarLength: number;
  calendarDate: Date;
  calendarAvailabilityVisible: boolean;
} = {
  selectedDate: null,
  modalTitle: CALENDAR_MODAL_TITLES.NEW_BOOKING,
  newBookingDefaultAppointments: [],
  calendarMessage: false,
  calendarTagCategoryId: null,
  calendarDefaultGutterSizes: [0, 100],
  calendarSelectedBookingType: CALENDAR_VIEWS.SLOTS,
  calendarRange: calcRange('week', new Date()),
  calendarView: Views.WEEK,
  calendarDatesCheckInCheckOutCount: [],
  calendarAvailabilityVisible: false,
  // unused
  calendarLength: 7,
  calendarDate: new Date()
};

export const persistedVarsStorageKeys: (keyof typeof initialStates)[] = [
  'calendarTagCategoryId',
  'calendarDefaultGutterSizes',
  'calendarSelectedBookingType',
  'calendarRange',
  'calendarView',
  'calendarDatesCheckInCheckOutCount',
  'calendarAvailabilityVisible'
];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
