exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-recovery-index-tsx": () => import("./../../../src/pages/account-recovery/index.tsx" /* webpackChunkName: "component---src-pages-account-recovery-index-tsx" */),
  "component---src-pages-account-recovery-update-password-tsx": () => import("./../../../src/pages/account-recovery/update-password.tsx" /* webpackChunkName: "component---src-pages-account-recovery-update-password-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-payment-setup-complete-tsx": () => import("./../../../src/pages/register/payment-setup-complete.tsx" /* webpackChunkName: "component---src-pages-register-payment-setup-complete-tsx" */)
}

