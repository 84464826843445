export const errorCodes = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  WRONG_EMAIL_OR_PASSWORD: 'WRONG_EMAIL_OR_PASSWORD',
  APP_USER_ALREADY_EXISTS: 'APP_USER_ALREADY_EXISTS',
  BRANCH_APP_USER_ALREADY_ADDED: 'BRANCH_APP_USER_ALREADY_ADDED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR'
};

export const errorDetails = {
  [errorCodes.USER_NOT_FOUND]: {
    message: 'User not found'
  },
  [errorCodes.WRONG_EMAIL_OR_PASSWORD]: {
    message: 'Wrong email or password'
  },
  [errorCodes.APP_USER_ALREADY_EXISTS]: {
    message: 'User already exists'
  },
  [errorCodes.BRANCH_APP_USER_ALREADY_ADDED]: {
    message: 'User already exists in branch'
  },
  [errorCodes.UNKNOWN_ERROR]: {
    // used to be we're fetching a ball
    message: ''
  }
};
