import { SelectedSubscriptionsFilter } from '../components/Shared/Filters/config';
import { generateVars } from './utils';

const initialStates: {
  selectedSubscriptionsFilters: SelectedSubscriptionsFilter[];
  subscriptionsHeaderActions: { id: string; action: JSX.Element }[];
} = {
  selectedSubscriptionsFilters: [],
  subscriptionsHeaderActions: []
};

export const persistedVarsStorageKeys = [];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
