import { SelectedProductsFilter } from '../components/Shared/Filters/config';
import { generateVars } from './utils';

const initialStates: {
  selectedProductsFilters: SelectedProductsFilter[];
  productsHeaderActions: { id: string; action: JSX.Element }[];
} = {
  selectedProductsFilters: [],
  productsHeaderActions: []
};

export const persistedVarsStorageKeys = [];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
