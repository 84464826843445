import { FieldPolicy } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import 'isomorphic-fetch';
import { getKeys } from './helpers';

export const memoryFields = {
  getBranchAppointments: {
    fields: undefined,
    keyArgs: ['GetBranchAppointmentsInput']
  },
  getBranchOrderSubscriptions: {
    fields: undefined,
    keyArgs: ['GetBranchOrderSubscriptionsInput']
  },
  getProducts: {
    fields: undefined,
    keyArgs: ['where']
  },
  getBranchPets: {
    fields: undefined,
    keyArgs: ['GetBranchPetsInput']
  },
  getBranchOrders: {
    fields: undefined,
    keyArgs: ['GetBranchOrdersInput']
  },
  getBranchChatRooms: {
    fields: undefined,
    keyArgs: ['GetBranchChatRoomsInput']
  },
  getMessages: {
    fields: undefined,
    keyArgs: ['GetMessagesInput']
  },
  getMedCondsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetMedCondsByPetRecordIdInput']
  },
  getNotesByMedCondId: {
    fields: undefined,
    keyArgs: ['GetNotesByMedCondIdInput']
  },
  getGroomingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetGroomingReportsByPetRecordIdInput']
  },
  getNotesByGroomingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByGroomingReportIdInput']
  },
  getTrainingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetTrainingReportsByPetRecordIdInput']
  },
  getNotesByTrainingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByTrainingReportIdInput']
  },
  getWalkingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetWalkingReportsByPetRecordIdInput']
  },
  getNotesByWalkingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByWalkingReportIdInput']
  },
  getSittingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetSittingReportsByPetRecordIdInput']
  },
  getNotesBySittingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesBySittingReportIdInput']
  },
  getDaycareReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetDaycareReportsByPetRecordIdInput']
  },
  getNotesByDaycareReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByDaycareReportIdInput']
  },
  getAppUserBranchCreditTransactionsByAppUserId: {
    fields: undefined,
    keyArgs: ['GetAppUserBranchCreditTransactionsByAppUserIdInput']
  },
  branchMessageTemplateGet: {
    fields: undefined,
    keyArgs: ['where']
  },
  getBranchAppointmentTags: {
    fields: undefined,
    keyArgs: ['GetBranchAppointmentTagsInput']
  },
  getBranchPetRecordTags: {
    fields: undefined,
    keyArgs: ['GetBranchPetRecordTagsInput']
  },
  getBranchAppUserTags: {
    fields: undefined,
    keyArgs: ['GetBranchAppUserTagsInput']
  },
  getBranchAppUsers: {
    fields: undefined,
    keyArgs: ['GetBranchAppUsersInput']
  },
  getBranchOrderInvoices: {
    fields: undefined,
    keyArgs: ['GetBranchOrderInvoicesInput']
  },
  getBranchTags: {
    fields: ['branchPetRecordTags', 'branchAppointmentTags', 'branchAppUserTags'],
    keyArgs: ['where']
  },
  getBranchFormsByPetRecordId: {
    fields: ['answeredForms', 'nonAnsweredForms'],
    keyArgs: ['GetBranchFormsByPetRecordIdInput', 'where']
  },
  getPharmaItems: {
    fields: undefined,
    keyArgs: ['GetPharmaItemsInput']
  },
  getPetRecordRecords: {
    fields: ['VaccRecords'],
    keyArgs: ['GetPetRecordRecordsInput']
  },
  getBranchOrderSubscriptionRepeats: {
    fields: undefined,
    keyArgs: ['GetBranchOrderSubscriptionRepeatsInput']
  },
  getBranchSubmittedForms: {
    fields: undefined,
    keyArgs: ['GetBranchSubmittedFormsInput']
  },
  getBranchDiscounts: {
    fields: undefined,
    keyArgs: ['GetBranchDiscountsInput']
  },
  getBranchBusUsers: {
    fields: undefined,
    keyArgs: ['GetBranchBusUsersInput']
  },
  getBranchPayouts: {
    fields: undefined,
    keyArgs: ['limit']
  },
  getBranchBalanceTransactions: {
    fields: undefined,
    keyArgs: ['limit']
  },
  getBranchProducts: {
    fields: undefined,
    keyArgs: ['GetBranchProductsInput']
  },
  getBranchSchedules: {
    fields: undefined,
    keyArgs: ['GetBranchSchedulesInput']
  },
  getBranchVouchers: {
    fields: undefined,
    keyArgs: ['GetBranchVouchersInput']
  },
  getBranchRooms: {
    fields: undefined,
    keyArgs: ['GetBranchRoomsInput']
  },
  getProviderBranches: {
    fields: undefined,
    keyArgs: ['GetProviderBranchesInput']
  },
  getBranchPetsViews: {
    fields: ['views'],
    keyArgs: ['GetBranchPetsViewsInput']
  },
  getBranchAppointmentsViews: {
    fields: ['views'],
    keyArgs: ['GetBranchAppointmentsViewsInput']
  },
  getBranchServiceOrdersViews: {
    fields: ['views'],
    keyArgs: ['GetBranchServiceOrdersViewsInput']
  },
  getBranchChatRoomsViews: {
    fields: ['views'],
    keyArgs: ['GetBranchChatRoomsViewsInput']
  },
  getBranchAppUsersViews: {
    fields: ['views'],
    keyArgs: ['GetBranchAppUsersViewsInput']
  },
  getBranchSubmittedFormsViews: {
    fields: ['views'],
    keyArgs: ['GetBranchSubmittedFormsViewsInput']
  },
  getBranchOrderSubscriptionsViews: {
    fields: ['views'],
    keyArgs: ['GetBranchOrderSubscriptionsViewsInput']
  }
};

export const combineFields = (data: Record<string, any>) => {
  const queryName = Object.keys(data)[0] as keyof typeof memoryFields;
  const fieldsToCombine = memoryFields[queryName]?.fields;
  let fieldsCombined = [] as Record<string, any>[];
  if (fieldsToCombine?.length) {
    const newFields = fieldsToCombine.reduce((acc, field) => {
      return [...acc, ...((data?.[queryName] as Record<string, Record<string, any>[]>)?.[field] || [])];
    }, fieldsCombined);
    fieldsCombined = newFields;
  } else {
    fieldsCombined = data?.[queryName] as Record<string, any>[];
  }
  return fieldsCombined;
};

type KeyArgs = Parameters<typeof offsetLimitPagination>[0];
type MemoryField = { fields?: string[]; keyArgs?: KeyArgs };

export const customOffsetLimitPagination = (memoryField: MemoryField) => {
  const keyArgs = memoryField?.keyArgs;
  return {
    ...offsetLimitPagination(keyArgs),
    merge: (...mergeArgs: Parameters<FieldPolicy['merge']>) => {
      const [existing, incoming, { args }] = mergeArgs;
      const { offset } = args as { offset: number };
      const hasFields = !!memoryField?.fields && !!memoryField.fields.length;

      if (offset === 0) {
        return incoming;
      }

      if (!hasFields) {
        return offsetLimitPagination(keyArgs)?.merge?.(...mergeArgs);
      }

      const existingKeys = getKeys(existing);

      const nonFieldsKeys = existingKeys?.filter(key => !memoryField.fields?.includes(key));

      const nonFields = nonFieldsKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: existing[key]
        };
      }, {});

      const merged = memoryField?.fields?.reduce((acc, key) => {
        const merged = offsetLimitPagination(keyArgs)?.merge?.(existing?.[key], incoming?.[key], { args });
        return { ...acc, ...nonFields, [key]: merged };
      }, {});
      return merged;
    }
  };
};
