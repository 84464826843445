import React from 'react';
import { ExternalToast, toast } from 'sonner';

type PropsType = Partial<ExternalToast> & { message: string | React.ReactNode };

const Notifier = function () {
  const defaultSettings: PropsType = {
    position: 'bottom-right',
    duration: 5000,
    message: '',
    richColors: true
  };

  const getProps = (props: PropsType) => {
    if (typeof props.message === 'string') {
      return { ...defaultSettings, ...props };
    }
    return { ...defaultSettings, ...props, style: { padding: 0, margin: 0, border: 'none', background: 'transparent' } };
  };

  const _error = (props: PropsType = defaultSettings) => toast.error?.(props.message, getProps(props));

  const _success = (props: PropsType = defaultSettings) => toast.success?.(props.message, getProps(props));

  const _warn = (props: PropsType = defaultSettings) => toast.warning?.(props.message, getProps(props));

  const _info = (props: PropsType = defaultSettings) => toast.info?.(props.message, getProps(props));

  const _default = (props: PropsType = defaultSettings) => toast(props.message, getProps(props));

  const _remove = (id: string | number) => toast.dismiss(id);

  return {
    error: _error,
    success: _success,
    warn: _warn,
    info: _info,
    default: _default,
    remove: _remove
    // action: _action
  };
};

const notifierInstance = Notifier();
export default notifierInstance;
